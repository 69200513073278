import React from "react";
import It from "../assets/lang-icons/it.svg";
import En from "../assets/lang-icons/en.svg";
import styles from "./LangIcon.module.scss";

export const LangIcon = ({ lang }) => {
  switch (lang) {
    case "it":
      return <It className={styles.langIcon} />;
    case "en":
      return <En className={styles.langIcon} />;
    default:
      return null;
  }
};
