/*
 * copy-block.js
 * -------------
 * Exports the copy-block component.
 */

import React from "react";

import mmWorkshopImage from "../../assets/images/mm-workshop.png";
import styles from "./copy-block.module.scss";

const CopyBlock = () => (
  <div className={styles.block}>
    <div className={styles.mmLogo}>
      <img src={mmWorkshopImage} alt="mm workshop" />
    </div>

    <div>
        CENTRO ARREDAMENTO MASANTA
    </div>

    <div>
      <span>
          MM WORKSHOP Srl
      </span>
      {" "}
        &copy;
      {" "}
      {new Date().getFullYear()}
    </div>

    <div>
      Via S. Ambrogio 10, 27058 Voghera (PV)
    </div>

    <div>
      P.IVA 02569800184
    </div>
  </div>
);

export default CopyBlock;
