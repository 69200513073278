/*
 * footer.js
 * ---------
 * Exports the footer component.
 */

import React from "react";

import Contact from "./contact";
import SocialIcons from "./social-icons";
import LinksBlock from "./links-block";
import CopyBlock from "./copy-block";
import styles from "./footer.module.scss";

const Footer = () => (
  <footer className={styles.footer}>
    <Contact />
    <SocialIcons />
    <LinksBlock />
    <CopyBlock />
  </footer>
);

export default Footer;
