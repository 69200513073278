/*
 * icon-button.js
 * --------------
 * Exports the icon-button component.
 */

import React from "react";
import PropTypes from "prop-types";

import styles from "./icon-button.module.scss";

const IconButton = ({ color, className, ...otherProps }) => {
  const classes = [
    styles.iconButton, styles[color],
    className,
  ];

  return (
    <button
      {...otherProps}
      className={classes.join(" ")}
    />
  );
};

IconButton.defaultProps = {
  color: "inheritColor",
  className: "",
};

IconButton.propTypes = {
  color: PropTypes.oneOf(["light", "dark", "inheritColor"]),
  className: PropTypes.string,
};

export default IconButton;
