import { defaultLang } from "../constants";

export const getIntlPagePath = (path, lang) => {
  if (!path.endsWith("/")) {
    path += "/";
  }

  if (lang === defaultLang) {
    return path;
  }

  return `/${lang}${path}`;
};
