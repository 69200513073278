/*
 * site-metadata.js
 * ----------------
 * Exports the site-metadata component.
 */

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import { useIntl } from "../hooks/useIntl";

const SiteMetadata = ({
  location, title, description, imagePublicPath, isArticle,
}) => {
  const { lang, pageLangVariants } = useIntl();

  const {
    site: {
      siteMetadata: {
        appName,
        siteUrl,
      },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          appName
          siteUrl
        }
      }
    }
  `);

  const hrefLangDefaultPageVariant = useMemo(() => {
    if (!pageLangVariants) {
      return null;
    }

    const englishPageVariant = pageLangVariants.find(({ lang: pageLang }) => pageLang === "en");

    return englishPageVariant || pageLangVariants[0];
  }, [pageLangVariants]);

  const sharable = !!imagePublicPath;
  const pathname = location.pathname.endsWith("/") ? location.pathname : `${location.pathname}/`;
  const pageUrl = `${siteUrl}${pathname}`;

  return (
    <Helmet defer={false} defaultTitle={appName} titleTemplate={`%s - ${appName}`}>
      <html lang={lang} />
      <meta name="theme-color" content="#ffffff" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="format-detection" content="telephone=no" />
      {description && <meta name="description" content={description} />}
      {sharable && [
        <meta property="og:url" key="og:url" content={pageUrl} />,
        <meta
          property="og:type"
          key="og:type"
          content={isArticle ? "article" : "website"}
        />,
        <meta
          name="twitter:card"
          key="twitter:card"
          content="summary_large_image"
        />,
        <meta property="og:title" key="og:title" content={title} />,
        <meta name="twitter:title" key="twitter:title" content={title} />,
        description && [
          <meta
            property="og:description"
            key="og:description"
            content={description}
          />,
          <meta
            name="twitter:description"
            key="twitter:description"
            content={description}
          />,
        ],
        imagePublicPath && [
          <meta
            property="og:image"
            key="og:image"
            content={`${siteUrl}${imagePublicPath}`}
          />,
          <meta
            name="twitter:image"
            key="twitter:image"
            content={`${siteUrl}${imagePublicPath}`}
          />,
        ],
        <meta property="og:site_name" key="og:site_name" content={appName} />,
      ]}
      <link rel="canonical" href={pageUrl} />
      {pageLangVariants && pageLangVariants.map((pageVariant) => (
        <link
          key={pageVariant.lang}
          rel="alternate"
          href={pageVariant.publicPath}
          hrefLang={pageVariant.lang}
        />
      ))}
      {hrefLangDefaultPageVariant && (
        <link
          rel="alternate"
          href={hrefLangDefaultPageVariant.publicPath}
          hrefLang="x-default"
        />
      )}
      <link
        rel="apple-touch-icon"
        href={`${siteUrl}/brand/icon/square-180x180.png"`}
      />
      <title>{title}</title>
    </Helmet>
  );
};

SiteMetadata.defaultProps = {
  description: "",
  imagePublicPath: "",
  isArticle: false,
};

SiteMetadata.propTypes = {
  location: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  imagePublicPath: PropTypes.string,
  isArticle: PropTypes.bool,
};

export default SiteMetadata;
