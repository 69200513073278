/*
 * header.js
 * ---------------
 * Exports the header component.
 */

import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Headroom from "react-headroom";
import { LangChooser } from "./LangChooser";
import IconButton from "./icon-button";
import LogoLink from "./logo-link";
import MenuIcon from "../assets/icons/menu.svg";
import styles from "./header.module.scss";

const Header = ({ headerTakesSpace, navLinks, onMenuButtonClick }) => (
  <Headroom wrapperStyle={headerTakesSpace ? {} : { maxHeight: 0 }}>
    <header className={styles.header}>
      <div className={styles.headerContent}>
        <IconButton className={styles.menuButton} onClick={onMenuButtonClick}>
          <MenuIcon />
        </IconButton>

        <LogoLink className={styles.logoLink} />

        <div className={styles.rightContent}>
          <nav className={styles.nav}>
            <ul className={styles.navList}>
              {navLinks.map((link) => (
                <li key={link.to} className={styles.navListItem}>
                  <Link
                    to={link.to}
                    className={styles.navItem}
                  >
                    {link.text}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>

          <LangChooser className={styles.langChooser} />
        </div>
      </div>
    </header>
  </Headroom>
);

Header.defaultProps = {
  headerTakesSpace: true,
};

Header.propTypes = {
  headerTakesSpace: PropTypes.bool,
  navLinks: PropTypes.arrayOf(PropTypes.object).isRequired,
  onMenuButtonClick: PropTypes.func.isRequired,
};

export default Header;
