/*
 * contact.js
 * ----------
 * Exports the contact component.
 */

import React from "react";
import { useTranslation } from "../../hooks/useTranslation";
import PlaceIcon from "../../assets/icons/place.svg";
import PhoneIcon from "../../assets/icons/phone.svg";
import WhatsappIcon from "../../assets/icons/whatsapp.svg";
import EmailIcon from "../../assets/icons/email.svg";
import styles from "./contact.module.scss";

const Contact = () => {
  const t = useTranslation();
  const tComponent = useTranslation("components.Footer");

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2 className={styles.heading} id={tComponent("ids.whereWeAre")}>
          {t("text.whereWeAre")}
        </h2>
        <a
          href="https://goo.gl/maps/yzpxrgPRYK4gRujL8"
          target="_blank"
          rel="noopener noreferrer nofollow"
          className={styles.link}
        >
          <span className={styles.icon}>
            <PlaceIcon />
          </span>
          <span className={`${styles.linkText} ${styles.address}`}>
            Corso XXVII Marzo, 58, Voghera, PV
          </span>
        </a>

        <a
          href="https://goo.gl/maps/b4vUgSBr7Ui9VVs56"
          target="_blank"
          rel="noopener noreferrer nofollow"
          className={styles.link}
        >
          <span className={styles.icon}>
            <PlaceIcon />
          </span>
          <span className={`${styles.linkText} ${styles.address}`}>
            Via Sant&apos;Ambrogio, 10, Voghera, PV
          </span>
        </a>

        <a
          href="https://goo.gl/maps/xbLhpv3hSVu1jAZo9"
          target="_blank"
          rel="noopener noreferrer nofollow"
          className={styles.link}
        >
          <span className={styles.icon}>
            <PlaceIcon />
          </span>
          <span className={`${styles.linkText} ${styles.address}`}>
            Via Nazionale, 555, Sestri Levante, GE
          </span>
        </a>
      </div>

      <div className={styles.content}>
        <h2 className={styles.heading} id={tComponent("ids.contact")}>
          {t("text.contact")}
        </h2>
        <a
          href="tel:+39-340-792-3863"
          className={styles.link}
        >
          <span className={styles.icon}>
            <PhoneIcon />
          </span>
          <span className={`${styles.linkText} ${styles.phoneNumber}`}>
            {tComponent("text.phoneNumber1")}
          </span>
        </a>
        <a
          href="tel:+39-0383-192-1235"
          className={styles.link}
        >
          <span className={styles.icon}>
            <PhoneIcon />
          </span>
          <span className={`${styles.linkText} ${styles.phoneNumber}`}>
            {tComponent("text.phoneNumber2")}
          </span>
        </a>
        <a
          href={`https://wa.me/393407923863/?text=${encodeURIComponent(tComponent("text.makeAnAppointmentPrefill"))}`}
          className={styles.link}
          rel="nofollow noopener noreferrer"
        >
          <span className={styles.icon}>
            <WhatsappIcon />
          </span>
          <span className={`${styles.linkText} ${styles.whatsapp}`}>
            {tComponent("text.makeAnAppointment")}
          </span>
        </a>
        <a
          href="mailto:info@centroarredamentomasanta.it"
          className={styles.link}
        >
          <span className={styles.icon}>
            <EmailIcon />
          </span>
          <span className={`${styles.linkText} ${styles.email}`}>
            info@centroarredamentomasanta.it
          </span>
        </a>
      </div>
    </div>
  );
};

export default Contact;
