/*
 * links-block.js
 * --------------
 * Exports the links-block component.
 */

import React, { useMemo } from "react";
import { Link } from "gatsby";
import { useTranslation } from "../../hooks/useTranslation";
import { useIntl } from "../../hooks/useIntl";
import markdownPagePaths from "../../markdownPagePaths.json";
import styles from "./links-block.module.scss";

const LinksBlock = () => {
  const { lang } = useIntl();
  const tComponent = useTranslation("components.Footer");

  const links = useMemo(() => [
    {
      to: markdownPagePaths.privacy[lang],
      text: tComponent("text.privacyLink"),
    },
  ], [lang, tComponent]);

  return (
    <ul className={styles.list}>
      {links.map((link) => (
        <li key={link.to} className={styles.listItem}>
          <Link
            to={link.to}
            className={styles.listItemLink}
          >
            {link.text}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default LinksBlock;
