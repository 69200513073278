/*
 * social-icons.js
 * ---------------
 * Exports the social-icons component.
 */

import React from "react";

import InstagramIcon from "../../assets/icons/instagram.svg";
import FacebookIcon from "../../assets/icons/facebook.svg";
import styles from "./social-icons.module.scss";

const socialLinks = [
  {
    href: "https://www.facebook.com/centroarredamentomasanta/",
    label: "Facebook",
    Icon: FacebookIcon,
    color: "#4172B8",
  },
  {
    href: "https://www.instagram.com/centroarredamentomasanta/",
    label: "Instagram",
    Icon: InstagramIcon,
    color: "#e4405f",
  },
];

const SocialIcons = () => (
  <ul className={styles.list}>
    {socialLinks.map(({ href, label, Icon }) => (
      <li key={href} className={styles.listItem}>
        <a
          href={href}
          aria-label={label}
          title={label}
          className={styles.listItemLink}
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          <Icon />
        </a>
      </li>
    ))}
  </ul>
);

export default SocialIcons;
