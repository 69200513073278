/*
 * logo-link.js
 * ------------
 * Exports the logo-link component.
 */

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Link, graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "../hooks/useTranslation";
import { useIntl } from "../hooks/useIntl";
import { getIntlPagePath } from "../utils/getIntlPagePath";
import LogoSymbol from "../assets/images/logo-symbol.svg";
import styles from "./logo-link.module.scss";

const LogoLink = ({ className, ...otherProps }) => {
  const { lang } = useIntl();
  const t = useTranslation();

  const {
    site: {
      siteMetadata: {
        appName,
      },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          appName
        }
      }
    }
  `);

  const classes = [
    styles.logoLink, className,
  ];

  const homePageLink = useMemo(() => (
    getIntlPagePath(t("pages.index.path"), lang)
  ), [t, lang]);

  return (
    <Link
      to={homePageLink}
      aria-label={appName}
      title={appName}
      className={classes.join(" ")}
      {...otherProps}
    >
      <LogoSymbol />
    </Link>
  );
};

LogoLink.defaultProps = {
  className: "",
};

LogoLink.propTypes = {
  className: PropTypes.string,
};

export default LogoLink;
