/*
 * navigation-drawer.js
 * --------------------
 * Exports the navigation-drawer component.
 */

import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import LogoLink from "./logo-link";
import IconButton from "./icon-button";
import CrossIcon from "../assets/icons/cross.svg";
import styles from "./navigation-drawer.module.scss";

const NavigationDrawer = ({ isOpen, navLinks, onClose }) => {
  const containerClasses = [styles.container];
  const tabIndex = isOpen ? 0 : -1;

  if (isOpen) {
    containerClasses.push(styles.isOpen);
  }

  return (
    <div className={containerClasses.join(" ")}>
      <div
        className={styles.backdrop}
        onClick={onClose}
        tabIndex={tabIndex}
      />

      <nav className={styles.nav}>
        <div className={styles.header}>
          <LogoLink
            onClick={onClose}
            className={styles.logoLink}
            tabIndex={tabIndex}
          />
          <IconButton
            className={styles.crossButton}
            style={{ position: "absolute" }}
            onClick={onClose}
            tabIndex={tabIndex}
          >
            <CrossIcon />
          </IconButton>
        </div>

        <div className={styles.body}>
          {navLinks.map((link) => (
            <Link
              key={link.to}
              to={link.to}
              onClick={onClose}
              className={styles.navLink}
              tabIndex={tabIndex}
            >
              {link.text}
            </Link>
          ))}
        </div>
      </nav>
    </div>
  );
};

NavigationDrawer.defaultProps = {
  isOpen: false,
};

NavigationDrawer.propTypes = {
  isOpen: PropTypes.bool,
  navLinks: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NavigationDrawer;
