import { useCallback } from "react";
import { translations } from "../translations";
import { useIntl } from "./useIntl";

export const useTranslation = (namespace = "") => {
  const { lang } = useIntl();

  const t = useCallback((translationId) => {
    const key = `${namespace && `${namespace}.`}${translationId}`;

    return translations[lang][key] || key;
  }, [namespace, lang]);

  return t;
};
