/*
 * layout.js
 * ---------------
 * Exports the layout component.
 */

import React, { useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import Header from "./header";
import Footer from "./footer/footer";
import NavigationDrawer from "./navigation-drawer";
import styles from "./layout.module.scss";
import { useTranslation } from "../hooks/useTranslation";
import { useIntl } from "../hooks/useIntl";
import { getIntlPagePath } from "../utils/getIntlPagePath";
import markdownPagePaths from "../markdownPagePaths.json";

const Layout = ({ children, headerTakesSpace }) => {
  const [navIsOpen, setNavIsOpen] = useState(false);

  const { lang } = useIntl();
  const t = useTranslation();
  const tComponent = useTranslation("components.Layout");

  const navLinks = useMemo(() => [
    {
      to: getIntlPagePath(t("pages.index.path"), lang),
      text: tComponent("text.homeLink"),
    },
    {
      to: markdownPagePaths.azienda[lang],
      text: tComponent("text.companyLink"),
    },
    {
      to: markdownPagePaths.showroom[lang],
      text: tComponent("text.showroomLink"),
    },
    {
      to: markdownPagePaths.prodotti[lang],
      text: tComponent("text.productsLink"),
    },
    {
      to: markdownPagePaths["chiavi-in-mano"][lang],
      text: tComponent("text.turnkeyFormulaLink"),
    },
    {
      to: markdownPagePaths["lavora-con-noi"][lang],
      text: tComponent("text.workWithUsLink"),
    },
    {
      to: `${getIntlPagePath(t("pages.index.path"), lang)}#${t("components.Footer.ids.contact")}`,
      text: tComponent("text.contactLink"),
    },
  ], [t, lang, tComponent]);

  const handleNavClose = useCallback(() => {
    setNavIsOpen(false);
  }, []);

  const openNav = useCallback(() => {
    setNavIsOpen(true);
  }, []);

  return (
    <>
      <NavigationDrawer
        navLinks={navLinks}
        isOpen={navIsOpen}
        onClose={handleNavClose}
      />

      <div className={styles.container}>
        <div className={styles.content}>
          <Header
            headerTakesSpace={headerTakesSpace}
            navLinks={navLinks}
            onMenuButtonClick={openNav}
          />
          { children }
        </div>

        <Footer />
      </div>
    </>
  );
};

Layout.defaultProps = {
  headerTakesSpace: true,
};

Layout.propTypes = {
  headerTakesSpace: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Layout;
