import React from "react";
import { Link } from "gatsby";
import { useIntl } from "../hooks/useIntl";
import { LangIcon } from "./LangIcon";
import styles from "./LangChooser.module.scss";

export const LangChooser = ({ className }) => {
  const { lang, pageLangVariants } = useIntl();

  return pageLangVariants && (
    <div className={`${styles.langChooser} ${className}`} tabIndex={0}>
      <div className={styles.currLangBlock}>
        <LangIcon lang={lang} />

        <span className={styles.langCode}>
          {lang}
        </span>
      </div>

      <div className={styles.otherLangsList}>
        {pageLangVariants.map((page) => {
          if (page.lang === lang) return null;

          return (
            <Link key={page.lang} to={page.publicPath} className={styles.otherLangLink}>
              <div className={styles.otherLangBlock}>
                <LangIcon lang={page.lang} />

                <span className={styles.langCode}>
                  {page.lang}
                </span>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
